import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Dialog               from "Components/Utils/Dialog";
import Button               from "Components/Utils/Button";
import Html                 from "Components/Utils/Html";
import Icon                 from "Components/Utils/Icon";



// Styles
const Container = Styled(Dialog)`
    width: 600px;
    min-height: 50px;
    max-height: calc(100dvh - 24px - 24px - 60px);

    @media (max-width: 600px) {
        margin-bottom: 60px;
    }
`;

const Inside = Styled.div`
    display: flex;
    gap: 24px;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Image = Styled.img`
    width: 240px;
    height: 240px;
    object-fit: cover;
`;

const Content = Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    flex-grow: 2;
    width: 100%;
`;

const Body = Styled.section`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
`;

const Title = Styled.h3`
    margin: 0;
    font-size: 20px;
`;

const Category = Styled.h4`
    margin: 0;
    font-size: 18px;

    :empty {
        display: none;
    }
`;

const Description = Styled(Html)`
    margin: 8px 0 0 0;
    color: rgb(24, 45, 103);
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    :empty {
        display: none;
    }
`;

const Field = Styled.p`
    margin: 0;
    color: rgb(24, 45, 103);
`;

const Price = Styled(Html)`
    font-size: 18px;
    font-weight: bold;
`;

const Footer = Styled.footer`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 16px;
`;

const Buttons = Styled.div`
    display: flex;
    background-color: var(--store-button-bg);
    color: var(--store-button-text);
    border-radius: var(--border-radius-small);

    .icon {
        padding: 6px 12px;
        font-size: 16px;
        cursor: pointer;
    }
`;

const Amount = Styled.div`
    display: flex;
    align-items: center;
`;

const Add = Styled(Button)`
    padding: 0 16px;
`;



/**
 * The Product
 * @returns {React.ReactElement}
 */
function Product() {
    const { currentProduct, cartAmounts, language } = Store.useState();

    const oldAmount = React.useMemo(() => cartAmounts[currentProduct] || 0, []);
    const [ newAmount, setNewAmount ] = React.useState(oldAmount);

    const setCart         = Store.useSetCart();
    const showProduct     = Store.useShowProduct();
    const getText         = Store.useText();
    const getCategoryText = Store.useCategoryText();
    const getProduct      = Store.useGetProduct();
    const getProductText  = Store.useProductText();


    // Variables
    const product  = getProduct(currentProduct);
    const name     = getProductText(currentProduct, "name");
    const fields   = Utils.parseFields(product, language, getText("yesNo"));
    const addPrice = Math.round(product.price * newAmount);


    // Handles the Add
    const handleAdd = () => {
        setNewAmount(newAmount + 1);
        setCart(currentProduct, newAmount + 1);
    };

    // Handles the Remove
    const handleRemove = () => {
        if (newAmount > 0) {
            setNewAmount(newAmount - 1);
            setCart(currentProduct, newAmount - 1);
        }
    };

    // Handles the Confirm
    const handleConfirm = () => {
        setCart(currentProduct, newAmount);
        showProduct();
    };

    // Handles the Close
    const handleClose = () => {
        setCart(currentProduct, oldAmount);
        showProduct();
    };


    // Do the Render
    return <Container onClose={handleClose}>
        <Inside>
            {product.hasImage && <Image
                src={product.imageUrl}
                alt={name}
            />}
            <Content>
                <Body>
                    <Title>{name}</Title>
                    <Category>{getCategoryText(product.categoryID, "name")}</Category>
                    <Description
                        content={getProductText(currentProduct, "description")}
                        addBreaks
                    />

                    {product.externalID && <Field>
                        <b>{getText("externalID")}:</b> {product.externalID}
                    </Field>}
                    {fields.map((field) => <Field key={field.id}>
                        <b>{field.name}:</b> {field.value}
                    </Field>)}
                    <Price>{Utils.formatPrice(product.price)}</Price>
                </Body>
                <Footer>
                    <Buttons>
                        <Icon icon="minus" onClick={handleRemove} />
                        <Amount>{newAmount}</Amount>
                        <Icon icon="plus" onClick={handleAdd} />
                    </Buttons>
                    <Add
                        message={`${getText("addToCart")} $ ${addPrice}`}
                        onClick={handleConfirm}
                        isSecondary
                    />
                </Footer>
            </Content>
        </Inside>
    </Container>;
}

export default Product;
